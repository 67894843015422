import { useEffect, useState } from "react"

const usePostMessageReady = () => {
  console.log('ready hook run')
  const [ready,setReady] = useState(false)
  useEffect(() => {
    console.log('ready effect run')
    if (window.postMessage) {
      setReady(true)
    } else {

    }
  }, [])
  return ready
}

export default usePostMessageReady