import { useEffect, useState } from "react"
import usePostMessageReady from "./usePostMessageReady"
const usePostMessageSubscribe = () => {
  console.log('sub hook run')
  const ready = usePostMessageReady()
  const [evtInfo,setEvtInfo] = useState<Array<any>>([])
  useEffect(() => {
    console.log('sub effect run')
    if (ready) {
      window.addEventListener('message', (e) => {
        const {data} = e
        evtInfo.forEach(item => {
          if (item.type === data.type) {
            item.handle(data)
          }
        })
      })
    }
    return () => {
    }
  }, [ready])
  const subFunc = (params: {
    type: string
    handle: (...args: any) => any
  }) => {
    setEvtInfo(state => [
      ...state,
      params
    ])
  }
  return subFunc
}

export default usePostMessageSubscribe