import { useEffect, useState } from "react"
import usePostMessageSubscribe from "./hooks/usePostMessageSubscribe"
const Demo = () => {
  console.log('demo render')
  const [count,setCount] = useState(0)
  const sub = usePostMessageSubscribe()
  useEffect(() => {
    console.log('demo effect run')
    sub({
      type: 'ok',
      handle: (data) => console.log(data)
    })
    sub({
      type: 'ok1',
      handle: (data) => console.log(data)
    })
  },[])

  return (
    <div>demo1
      <button onClick={() => setCount(count+1)}>add</button>
    </div>
  )
}

export default Demo