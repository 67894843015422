import Demo from "./custom-hook/Demo"

const App = () => {
  return (
    <div>
      <Demo />
    </div>
  )
}

export default App
