import React, {StrictMode} from 'react'
import { createRoot } from 'react-dom/client'
import App from './app'
const root = createRoot(document.getElementById('app') as Element)

root.render(
  // <StrictMode>
     <App/>
  //  </StrictMode>
)

// root.render(App())